import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "ant-design-vue/dist/antd.css";
import { message } from "ant-design-vue";

import "./utils/request";
import dynamicSize from "./utils/dynamicSize";

Vue.use(dynamicSize);
Vue.prototype.$message = message;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
<div v-if="visible" class="mask" @click="close">
  <div class="modal" @click.stop>
    <img v-if="isMobile" src="@/assets/rank-images/mobile/btn_close.png" class="close" @click="close">
    <img v-else src="@/assets/rank-images/btn_close.png" class="close" @click="close">

    <img class="title" src="@/assets/rank-images/pop_title_PR.png"></img>

    <div v-if="!myInfo?.rank || myInfo?.rank > 500" class="content">
      <div>SORRY,YOU ARE NOT ON THE LEADER-</div>
      <div>BOARD YET.KEEP UP THE EFFORT!</div>
    </div>
    <div v-else class="content ranking">
      <div class="switch">
        <span class="active">RANK</span>
        <span style="flex: 1;text-align: center;">NAME</span>
      </div>
      <div class="tip">POINTS</div>
      <div class="myInfo" :style="{ backgroundImage: `url(${myInfo.rank > 3 ? require('@/assets/rank-images/bg.png') : require('@/assets/rank-images/bg_front.png')})` }">
        <span class="seq" v-if="myInfo.rank > 3">{{ myInfo.rank }}</span>
        <div class="seq" v-else><img :src="require(`@/assets/rank-images/${(myInfo.rank)}.png`)"></div>
        <span class="name">{{ myInfo.name }}</span>
        <span class="point">{{ myInfo.point }}</span>
      </div>
    </div>

    <img class="confirm" @click="close" src="@/assets/rank-images/btn_confirm.png">
  </div>
</div>
</template>

<script>
export default {
  name: 'modal',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      myInfo: null,
      worldId: '',
      areaId: '',
      address: '',
      type: null
    }
  },
  computed: {
    dataRange() {
      const { worldId, areaId, type, address } = this;
      return { worldId, areaId, type, address };
    }
  },
  watch: {
    dataRange() {
      this.getMyRank();
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    /**
   * get my rank
   */
    async getMyRank() {
      const { worldId, areaId, type, address } = this;
      
      try {   
        const { data, code } = await this.$axios.get('ranking/myRanking', { params: { worldId, areaId, type, address }});
        if(code == 200) {
          this.myInfo = data;
        }
        // else {
        //   localStorage.setItem('rankType', type);
        //   loginVerify()
        // }
      } catch (error) {
        console.log(error);
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: relative;
    width: 9.2rem;
    height: 4.6667rem;

    background-image: url('@/assets/rank-images/pop_com.png');
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .close {
      width: .48rem;
      object-fit: contain;
      cursor: pointer;

      position: absolute;
      top: -0.256rem;
      right: 0.65rem;
    }

    .title {
      width: 4.5467rem;
      object-fit: contain;
    }

    .content {
      font-family: 'Roboto-Medium';
      font-size: .2133rem;
    }

    .ranking {
      margin-top: 1rem;
      width: 5.64rem;
      position: relative;
      overflow: visible;
      $tip-height: 0.36rem;

      .switch {
        width: 2.4rem;
        height: $tip-height;
        font-size: 0.2133rem;
        background: #000;
        border: 1px solid #777777;

        display: flex;
        align-items: center;

        position: absolute;
        top: -$tip-height;
        left: 0;

        span.active {
          padding-left: 0.25rem;
          width: 1.07rem;
          height: $tip-height;
          line-height: $tip-height;
          flex-shrink: 0;
          margin-left: -0.01rem;

          background-image: url('@/assets/rank-images/bg_active.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: -1px -1px;
        }
      }

      .tip {
        width: 1rem;
        height: $tip-height;
        line-height: $tip-height;
        text-align: center;
        font-size: 0.2133rem;
        color: #000;
        background: #FFF;

        position: absolute;
        top: -$tip-height;
        right: 1px; // background image is 1px off
      }

      .myInfo {
        width: 100%;
        height: 0.55rem;
        font-size: 0.2677rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        display: flex;
        align-items: center;

        .seq, .name, .point {
          display: flex;
          justify-content: center;
        }

        .seq {
          width: 0.7rem;

          >img {
            width: 0.35rem;
            height: 0.35rem;
            object-fit: contain;
          }
        }

        .name {
          color: #000;
          flex: 1;
        }

        .point {
          width: 1.7rem;
        }
      }
    }

    .confirm {
      width: 5.64rem;
      margin-top: .32rem;
      object-fit: contain;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 993px) {
    .modal {
      transform: translateY(-1rem);
      width: 5.0867rem;
      height: 2.9033rem;
      background-image: url('@/assets/rank-images/mobile/pop_com.png');

      .close {
        width: .4rem;

        position: absolute;
        top: auto;
        bottom: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
      }

      .title {
        width: 3.2rem;
      }

      .ranking {
        width: 3.7733rem;
        $tip-height: 0.3rem;
        margin-top: calc(0.32rem + $tip-height);

        .switch {
          width: 1.7333rem;
          height: $tip-height;
          font-size: .1867rem;
          border: none;
          top: -$tip-height;

          span.active {
            padding-left: 0.2rem;
            width: .9267rem;
            height: $tip-height;
            line-height: $tip-height;
          }
        }

        .tip {
          height: $tip-height;
          line-height: $tip-height;
          font-size: .1867rem;
          top: -$tip-height;
        }

        .myInfo {
          height: .4533rem;
          font-size: .2133rem;

          .seq {
            width: 0.5rem;
          }

          .point {
            width: 1.2rem;
          }
        }
      }

      .confirm {
        width: 3.7733rem;
        margin-top: .32rem;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}
</style>
import Vue from "vue";
import { MmSmartAccount } from "@mixmarvel/mmsdk_mpc";

/**
 * Login
 */
export async function loginVerify() {
    try {
      // Redirect to the MixMarvel server to get the code
      const { data, code, message } = await Vue.prototype.$axiosToken.get('/login/MixMarvel');
      if (code !== 200) {
        this.$message.error(message);
        return;
      }
  
      // Redirect to the MixMarvel server
      window.location.href = data;
    } catch (error) {
      console.log(error);
    }
}

/**
 * Get address
 * @param {String} datacode // The code returned by the MixMarvel server
 * @param {String} datakeyHash // The keyHash returned by the MixMarvel server
 * @param {String} state // The state returned by the MixMarvel server
 */
export async function getAddress(datacode, datakeyHash, state) {
    // Get the message from the MixMarvel server
  try {
    return await new Promise(async (resolve, reject) => {
      const { code, data, message } = await Vue.prototype.$axiosToken.post('/mixmarvel/callback', { code: datacode, keyHash: datakeyHash, state });

      if (code !== 200) {
        this.$message.error(message);
        return
      }
  
      const { appId, appPubKey, keyHash, keySalt } = data;
      const MpcOptions = {
        env: 0,
        appId,
        authInfo: {
          keyHash,
          appPubKey,
          keySalt
        }
      };
      const mpc = new MmSmartAccount(MpcOptions);
    
      await mpc.init();
      const address = mpc.getAddress();
      localStorage.setItem("address", address);
      console.log(address);
      
      resolve(address);

      setTimeout(() => {
        reject('Timeout');
      }, 1000 * 60 * 10); // 10 minutes
    });
  } catch (error) {
    console.log(error);
  }
}
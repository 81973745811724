<template>
  <div class="__page__">
    <div class="page-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.theme-thin {
  --os-handle-bg: #3E68FF9f;
  --os-handle-bg-hover: #3E68FF;
  --os-handle-bg-active: #3E68FF;
}

.__page__ {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: black;
  position: relative;

  // @include up(lg) {
  //   >.page-container {
  //     max-width: 25.6rem;
  //     margin: auto;
  //     overflow: hidden;
  //   }
  // }
}
</style>

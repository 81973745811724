<template>
  <div class="charts" >
    <div class="filtrate">
      <label for="region">REGION：</label>
      <div class="select" @click.stop="currentSelect = 'region'">
        <img src="@/assets/rank-images/filter_arrow.png" class="arrow">
        <input type="text" name="region" id="region" :value="region?.name" readonly placeholder="region">
        <ul class="options" v-if="currentSelect == 'region'">
          <li @click.stop="currentSelect = ''" :class="{ active: region?.id == item.id }" v-for="item in regionList" :key="item.id" @click="selectChange('region', item)">{{ item.name }}</li>
        </ul>
      </div>
      <label for="server">SERVER：</label>
      <div class="select" @click.stop="currentSelect = 'server'">
        <img src="@/assets/rank-images/filter_arrow.png" class="arrow">
        <input type="text" name="server" id="server" :value="server?.name" readonly placeholder="server">
        <ul class="options" v-if="currentSelect == 'server' && serverList.length">
          <li @click.stop="currentSelect = ''" :class="{ active: server?.id == item.id }" v-for="item in serverList" :key="item.id" @click="selectChange('server', item)">{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="list">
      <list :style="{ borderBottom: $attrs.isMobile ? '1px solid #FFFFFF14' : 'none' }" title="GOLDEN GLORY LEADERBOARD" :worldId="server?.id" :areaId="region?.id" v-bind="$attrs" />
<!--      <list title="ENTERTAINMENT LEADERBOARD" :worldId="server?.id" :areaId="region?.id" v-bind="$attrs" />-->
    </div>
<!--    <div class="tips">Tips: If points are tied, prize pool will be equally divided among the users with the same points, and users beyond top 500 with the same score will also be listed on leaderboard.</div>-->
  </div>
</template>

<script>
import list from './list.vue';
export default {
  name: 'rank',
  components: { list },
  data() {
    return {
      serverList: [],
      // regionList: [
      //   {
      //     id: 999,
      //     name: 'TEST',
      //     serverList: [
      //       {
      //         id: 1,
      //         name: 'TEST'
      //       }
      //     ]
      //   }
      // ],
      regionList: [
        {
          id: 1,
          name: 'ASIA',
          serverList: [
            {
              id: 1,
              name: 'Law of Earth'
            },
            {
              id: 2,
              name: 'Mirror of Water'
            },
            // {
            //   id: 3,
            //   name: 'Incantation of Fire'
            // },
            // {
            //   id: 4,
            //   name: 'Whisper of Wind'
            // },
          ]
        },
        {
          id: 2,
          name: 'AMERICA',
          serverList: [
            {
              id: 101,
              name: "Mercury's Wings"
            },
            {
              id: 102,
              name: "Venus' Heart"
            },
            // {
            //   id: 103,
            //   name: "Earth's Shield"
            // },
            // {
            //   id: 104,
            //   name: "Mars' Sword"
            // },
          ]
        }
      ],
      region: null,
      server: null,
      currentSelect: '',
    }
  },
  watch: {
    region(val) {
      this.serverList = this.regionList.find(item => item.id === val.id)?.serverList || [];
      this.server = this.serverList[0];
    }
  },
  async mounted() {
    // close select when click outside
    document.addEventListener('click', () => {
      if(this.currentSelect) this.currentSelect = ''
    })

    this.region = this.regionList[0];
    this.serverList = this.serverList[0];
  },
  methods: {
    /**
     * click select
     * @param {String} type
     * @param {String} value
     */
    selectChange(type, value) {
      this[type] = value;
      this.currentSelect = '';
    }
  }
}

</script>
<style lang='scss' scoped>
.charts {
  width: 13.8rem;
  .filtrate {
    display: flex;
    align-items: center;
    font-size: 0.2533rem;

    div.select {
      position: relative;
      cursor: pointer;
      width: 2.2rem;
      background-image: url('@/assets/rank-images/filter.png');
      background-repeat: no-repeat;
      background-size: contain;

      &:first-of-type {
        margin-right: 0.5rem;
      }

      img.arrow {
        position: absolute;
        width: 0.12rem;
        object-fit: contain;
        top: 50%;
        transform: translateY(-50%);
        right: 0.2rem;
      }

      ul.options {
        position: absolute;
        width: 2.2rem;
        transform: translateY(-2px);
        max-height: 4rem;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid #fff;
        background: rgba(0,0,0,0.85);
        z-index: 999;

        >li {
          padding: 0.1rem 0.2rem;
          cursor: pointer;
          font-size: 0.2133rem;
          color: #fff;
          border-bottom: 1px solid transparent;

          &:not(:first-child) {
            border-top: 1px solid #fff;
            &.active {
              border-top:  1px solid #3E68FF;
            }
            &:hover {
              border-top:  1px solid #3E68FF;
            }
          }

          &:hover {
            background: #3E68FF;

            &+li {
              border-top:  1px solid #3E68FF;
            }
          }
        }

        >li.active {
          background: #3E68FF;

          &+li {
            border-top:  1px solid #3E68FF;
          }
        }
      }

      input {
        all: unset;
        width: 80%;
        height: 0.47rem;
        font-size: 0.2133rem;
        box-sizing: border-box;
        padding-left: 0.2rem;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
  }

  .tips {
    font-size: 0.25rem;
    color: #fff;
    opacity: 0.7;
    margin-top: 0.2rem;
  }
}

// mobile
@media only screen and (max-width: 993px) {
  .charts.charts {
    width: 100%;
    overflow: hidden;

    .filtrate {
      font-size: .1867rem;

      div.select {
        &:first-of-type {
          margin-right: 0.3rem;
        }

        img.arrow {
          width: 0.1rem;
          right: 0.13rem;
        }

        ul.options {
          width: 1.4rem;
          max-height: 4rem;
          transform: translateY(-1px);

          >li {
            padding: 0.05rem 0.1rem;
            font-size: .1867rem;
          }
        }

        input {
          width: 80%;
          height: .3rem;
          font-size: .1867rem;
          padding-left: 0.1rem;
          background-size: 100% 100%;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
    }
  }
}

</style>

<template>
<div class="components-list">
  <div v-if="isMobile" class="mobile-title">
<!--    <img class="text" :src="titleMap[title].title">-->
<!--     <img class="location" @click="goMyRank()" src="@/assets/rank-images/mobile/button_myranking.png">-->
  </div>
<!--  <div v-else class="title">{{ title }}<img src="@/assets/rank-images/arrow.png" /></div>-->

<!--   <div v-if="!isMobile" class="myrank" @click="goMyRank()">MY RANKING</div>-->
  <div class="ranking" v-if="dataList.length">
    <div class="switch">
      <span class="active">RANK</span>
      <span style="flex: 1;text-align: center;">NAME</span>
    </div>
    <div class="tip">POINTS</div>
    <ul class="datalist" >
      <li class="item" v-for="(item, index) in dataList" :key="item.ranking + item.name + index" :style="{ backgroundImage: `url(${item.ranking > 3 ? require('@/assets/rank-images/bg.png') : require('@/assets/rank-images/bg_front.png')})` }">
        <span class="seq" v-if="item.ranking > 3">{{ item.ranking }}</span>
        <div class="seq" v-else><img :src="require(`@/assets/rank-images/${item.ranking}.png`)"></div>
        <span class="name">{{ item.name }}</span>
        <span class="point">{{ item.totalPoints }}</span>
      </li>
    </ul>
  </div>
  <img class="empty" src="@/assets/rank-images/mobile/empty.png" v-else-if="isMobile && titleMap[title].showEmpty">
  <img class="empty" src="@/assets/rank-images/empty.png" v-else-if="titleMap[title].showEmpty">
  <div class="pagination" v-if="dataList.length">
    <Pagination v-model="formData.pageNo" size="small" showLessItems show-quick-jumper :total="total" :pageSize="formData.pageSize" /><span v-if="!isMobile">Page</span>
  </div>
  <Modal ref="modal" :isMobile="isMobile" />
</div>
</template>

<script>
import Modal from './modal.vue';
import Pagination from 'ant-design-vue/lib/pagination';
import { loginVerify, getAddress } from '@/utils/login';
export default {
  components: { Pagination, Modal },
  name: 'list',
  props: {
    title: String, // rank title
    worldId: { // filter by worldId
      type: Number,
      default: 0,
    },
    areaId: { // filter by areaId
      type: Number,
      default: 0,
    },
    isMobile: { // is mobile
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: { // form data
        pageNo: 1,
        pageSize: 15,
        worldId: '',
        areaId: '',
      },
      total: 0, // total number of data
      dataList: [], // data list
      titleMap: {
        'GOLDEN GLORY LEADERBOARD': {
          title: require('@/assets/rank-images/mobile/charts_title1.png'),
          api: 'ranking/mainRanking',
          type: 0,
          showEmpty: false,
        },
        'ENTERTAINMENT LEADERBOARD': {
          title: require('@/assets/rank-images/mobile/charts_title2.png'),
          api: 'ranking/gameRanking',
          type: 1,
          showEmpty: true,
        },
      },
    }
  },
  async mounted() {
    this.getData();
    if(this.checkType()) return;

    // If the address is not stored in the local storage, get the address from the MixMarvel server
    if(!window.location.search) return;

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const keyHash = params.get("keyHash");
    const state = params.get("state");

    if (!(code && keyHash && state)) {
      this.$message.error('Invalid parameters');
      return;
    }

    if (localStorage.getItem('address')) return;
    const address = await getAddress(code, keyHash, state);

    if(!address) {
      this.$message.error('Failed to get address');
      return;
    }

    this.goMyRank(address);
  },
  watch: {
    async worldId() {
      this.formData.worldId = this.worldId;
      this.formData.pageNo = 1;
    },
    async areaId() {
      this.formData.areaId = this.areaId;
      this.formData.pageNo = 1;
    },
    formData: {
      async handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * go to my rank
     * @param {?String} data - address
     */
    async goMyRank(data) {
      let address = data || localStorage.getItem('address');

      if(!address) {
        localStorage.setItem('rankType', this.titleMap[this.title].type);
        this.$message.warning('Please login first');
        loginVerify();
        return;
      }

      if(this.checkType()) return;
      const type = localStorage.getItem('rankType');
      localStorage.removeItem('rankType');

      this.$refs.modal.worldId = this.worldId;
      this.$refs.modal.areaId = this.areaId;
      this.$refs.modal.type = type ?? this.titleMap[this.title].type;
      this.$refs.modal.address = address;
      this.$refs.modal.visible = true;
    },
    /**
     * get data from server
     */
    async getData() {
      try {
        if(!this.formData.worldId || !this.formData.areaId) return;

        const { data, code } = await this.$axios.get(this.titleMap[this.title].api, { params: this.formData });
        if(code !== 200) return;
        this.dataList = data.records;
        this.total = data.total;
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * check type
     */
    checkType() {
      const type = localStorage.getItem('rankType');
      if((type == 0 || type == 1) && type - 0 != this.titleMap[this.title].type) return true;
    }
  }
}

</script>
<style lang='scss' scoped>
.components-list {
  width: 6.6rem;

  .title {
    font-family: "RobotoMono-Bold";
    font-size: 0.4rem;
    font-weight: bold;
    line-height: 1;
    margin: 0.3rem 0 0.05rem;

    display: flex;
    align-items: center;

    >img {
      margin-left: 0.05rem;
      width: 0.3rem;
      object-fit: contain;
    }
  }

  .myrank {
    width: fit-content;
    margin-top: 0.2rem;
    font-size: 0.2647rem;
    text-decoration: underline;
    color: #FFC200;
    cursor: pointer;
  }

  .ranking {
    margin-top: 0.75rem;
    position: relative;
    overflow: visible;
    $tip-height: 0.36rem;

    .switch {
      width: 2.4rem;
      height: $tip-height;
      font-size: 0.2133rem;
      background: #000;
      border: 1px solid #777777;

      display: flex;
      align-items: center;

      position: absolute;
      top: -$tip-height;
      left: 0;

      span.active {
        padding-left: 0.25rem;
        width: 1rem;
        height: $tip-height;
        line-height: $tip-height;
        flex-shrink: 0;
        margin-left: -0.01rem;

        background-image: url('@/assets/rank-images/bg_active.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: -1px -1px;
      }
    }

    .tip {
      width: 1rem;
      height: $tip-height;
      line-height: $tip-height;
      text-align: center;
      font-size: 0.2133rem;
      color: #000;
      background: #FFF;

      position: absolute;
      top: -$tip-height;
      right: 1px; // background image is 1px off
    }

    .datalist {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 6.5rem);

      &::-webkit-scrollbar {
        width: 0;
      }

      .item {
        list-style: none;
        width: 100%;
        height: 0.55rem;
        font-size: 0.2677rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 0.16rem;
        }

        .seq, .name, .point {
          display: flex;
          justify-content: center;
        }

        .seq {
          width: 0.75rem;

          >img {
            width: 0.35rem;
            height: 0.35rem;
            object-fit: contain;
          }
        }

        .name {
          color: #000;
          flex: 1;
        }

        .point {
          width: 2rem;
        }
      }
    }
  }

  .empty {
    width: 6rem;
    margin-top: 0.5rem;
    object-fit: contain;
  }

  .pagination {
    margin-top: 0.15rem;
    display: flex;
    align-items: center;
  }

  :deep(.ant-pagination.mini) {
    display: flex;

    .ant-pagination-item {
      margin-right: 5px;
    }
    .ant-pagination-item-active {
      background: #3E68FF;
      border-color: #3E68FF;
      border-radius: 2px;
    }
    .ant-pagination-item:not(.ant-pagination-item-active) {
      background: #A6A6A6;
      border-color: #A6A6A6;
      border-radius: 2px;
    }
    a {
      color: #FFF;
    }
    .ant-pagination-options-quick-jumper {
      color: #FFF;
    }
  }
}

@media only screen and (max-width: 993px) {
  .components-list {
    width: 100%;

    .mobile-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 0.32rem;

      .text {
        width: 2.5067rem;
        object-fit: contain;
      }

      .location {
        width: 1.5467rem;
        height: .4267rem;
      }
    }
    .ranking {
      $tip-height: 0.3rem;
      margin-top: calc(0.32rem + $tip-height);

      .switch {
        width: 1.7333rem;
        height: $tip-height;
        font-size: .1867rem;
        border: none;
        top: -$tip-height;

        span.active {
          padding-left: 0.2rem;
          width: .9267rem;
          height: $tip-height;
          line-height: $tip-height;
        }
      }

      .tip {
        height: $tip-height;
        line-height: $tip-height;
        font-size: .1867rem;
        top: -$tip-height;
      }

      .datalist {
        max-height: 5.5rem;

        &::-webkit-scrollbar {
          width: 0;
        }

        .item {
          height: .4533rem;
          font-size: .2133rem;

          &:not(:last-child) {
            margin-bottom: .1067rem;
          }

          .seq {
            width: 0.65rem;
          }

          .point {
            width: 1.5rem;
          }
        }
      }
    }

    .empty {
      width: 100%;
      margin-top: 0.2;
    }

    .pagination {
      margin-bottom: .32rem;
      justify-content: center;
    }

    :deep(.ant-pagination.mini) {
      display: flex;

      a {
        color: #FFF;
      }

      .ant-pagination-item {
        opacity: 0.5;
        margin-right: .1467rem;
        background: none;
        border-color: #FFF;
        border-radius: 0;
      }
      .ant-pagination-item-active {
        opacity: 1;
      }
      .ant-pagination-item:not(.ant-pagination-item-active) {
        background: none;
        border-color: #FFF;
        border-radius: 0;
      }

      .ant-pagination-item-link-icon {
        opacity: 0;
      }

      .ant-pagination-item-ellipsis {
        opacity: 0.5;
        font-size: .1rem;
        color: #FFF;
        background: none;
        margin-right: .1467rem;
        border: 1px solid #FFF;
      }

      .ant-pagination-jump-next .ant-pagination-item-container, .ant-pagination-jump-prev .ant-pagination-item-container {
        width: 0.4rem;
      }

      .ant-pagination-prev, .ant-pagination-next {
        width: .4rem;
        margin-right: .1467rem;
        opacity: 0.5;
        border: 1px solid #FFF;
        border-radius: 0;

        & :hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
<template>
  <div class="page">
    <img src="@/assets/rank-images/mobile/logo.png" class="logo">
    <img src="@/assets/rank-images/mobile/title.png" class="title">

    <div class="jump">
      <img src="@/assets/rank-images/mobile/button1.png" @click="jump('rules')">
      <img src="@/assets/rank-images/mobile/button2.png" @click="jump('activity')">
      <img src="@/assets/rank-images/mobile/button3.png" @click="jump('charts')">
    </div>

    <img id="activity" src="@/assets/rank-images/mobile/a.png" class="activity">
    <a href="https://tmak.metacene.io/" class="metaceneGO" style="color: rgb(94, 155, 233);margin-left: 10px;font-size: 12px">https://tmak.metacene.io/</a>
    <img id="activity" src="@/assets/rank-images/mobile/b.png" style="margin-top: 0rem" class="activity">
    <img src="@/assets/rank-images/mobile/line.png" class="line">


    <img id="rules" src="@/assets/rank-images/mobile/rules.png" class="rules">
    <div class="btn">
      <a href="https://www.metacene.io/news/f1021b13b7b94b7ea49fb573de17d194"><img
        src="@/assets/rank-images/mobile/rule_detail.png" class="detail"></a>
<!--      <a href="https://www.metacene.io/events/list"><img src="@/assets/rank-images/mobile/jumpHome.png"-->
<!--                                                         class="jumpHome"></a>-->
    </div>
    <img src="@/assets/rank-images/mobile/line.png" class="line">


<!--    <img id="activity" src="@/assets/rank-images/mobile/c.png" style="margin-top: 0rem" class="activity">-->

<!--     <img src="@/assets/rank-images/mobile/charts.png">-->
    <div id="charts" class="charts" >
      <img class="title" src="@/assets/rank-images/mobile/charts_title.png">
      <charts :isMobile="true" />
<!--      <img src="@/assets/rank-images/comming_soon.png" style="height: 150px">-->
    </div>

    <img src="@/assets/rank-images/mobile/bottom.png" class="bottom">
  </div>
</template>
<script>
import charts from "./charts/charts.vue";

export default {
  components: { charts },
  name: "pageMobile",
  methods: {
    /**
     * Jump to the element with the given id
     * @param id {String} - id of the element to jump to
     */
    jump(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  }
};

</script>
<style lang='scss' scoped>
// page-container
.page {
  width: 100vw;
  min-height: 100vh;
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  position: relative;
  font-family: "RobotoMono-Regular";

  display: flex;
  flex-direction: column;

  background-image: url('@/assets/rank-images/mobile/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  .logo {
    width: 2rem;
    object-fit: contain;
  }

  .title {
    width: 100%;
    object-fit: contain;
    margin-top: 0.6rem;
  }

  .jump {
    width: 100%;
    margin-top: 3.5rem;
    display: flex;
    justify-content: space-between;

    > img {
      width: 30%;
    }
  }

  .line {
    width: 100%;
    object-fit: contain;
    margin-top: 0.5rem;
  }

  .rules {
    width: 100%;
    object-fit: contain;
    margin-top: 0.5rem;
  }

  .btn {
    display: flex;
    align-items: center;
    margin-top: 0.1rem;

    .detail {
      width: 0.75rem;
      margin-right: 0.3rem;
    }

    .jumpHome {
      width: 3.6rem;
    }
  }

  .activity {
    width: 100%;
    object-fit: contain;
    margin-top: 0.5rem
  }
  .metaceneGO{
    height: 20px;
    width: 180px;
    //position: absolute;
    //bottom: 4.15rem;
    //background-color: red;
  }
  .charts {
    color: #FFF;

    .title {
      width: 2.1067rem;
      margin-bottom: .32rem;
      object-fit: contain;
    }
  }

  img.charts {
    margin-top: 0.5rem;
    width: 100%;
    object-fit: contain;
  }

  .bottom {
    width: 98%;
    object-fit: contain;
    margin: 0.2rem auto 0;
  }
}
</style>

<template>
  <pageMobile v-if="isMobile" />
  <pagePC v-else />
</template>

<script>
import pagePC from "./components/pc.vue";
import pageMobile from "./components/mobile.vue";
export default {
  components: { pagePC, pageMobile },
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    const media = window.matchMedia("(max-width: 993px)");
    this.isMobile = media.matches;
    media.addEventListener("change", this.handleMediaChange);
  },
  methods: {
    handleMediaChange(e) {
      this.isMobile = e.matches;
    },
  },
};
</script>

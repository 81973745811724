export default () => {
    (function (win, doc) {
        if (!win.addEventListener) return;
        var html = document.documentElement;

        function setFont() {
            var html = document.documentElement;
            var k = 2560;
            if(html.clientWidth > 992) {
                k = 2560;
            }else{
                k = 546;
            }
            html.style.fontSize = Math.min(2560,html.clientWidth) / k * 100 + "px";
        }
        setFont();
        setTimeout(function () {
            setFont();
        }, 300);
        doc.addEventListener('DOMContentLoaded', setFont, false);
        win.addEventListener('resize', setFont, false);
        win.addEventListener('load', setFont, false);
    })(window, document);

}

import axios from 'axios';
import Vue from 'vue';

console.log(process.env.VUE_APP_BASE_API);

const axiosInstance = axios.create({
  // baseURL: 'https://website-backend.metacene.io/',
  // baseURL: 'https://test-website.metacene.io/admin/',
  // baseURL: 'https://h5.9shadow.com/metacene-admin/',
  baseURL: 'https://rank.metacene.io/metacene-ranking-server/', // prod
  // baseURL: 'https://test.9shadow.com/metacene-ranking/', // test
  // baseURL: 'https://rank.metacene.io/metacene-ranking-server-test/', // test
  // baseURL: '/api/', // dev
});

axiosInstance.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response.data;
}, error => {
  return Promise.reject(error);
});

const axiosToken = axios.create({
  baseURL: 'https://gameplus-web.qa.davionlabs.com/api/metacence/v1/promotion/',
  headers: {
    'Content-Type': 'application/json',
    'category': 'ranking'
  }
})

axiosToken.interceptors.response.use(response => {
  return response.data;
}, error => {
  return Promise.reject(error);
});

Vue.prototype.$axios = axiosInstance;
Vue.prototype.$axiosToken = axiosToken;

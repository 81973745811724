var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('video',{staticClass:"video-bg",attrs:{"src":"https://leaderboard.metacene.io/img/screen_video.mp4","autoplay":"","loop":"","muted":"","controls":false},domProps:{"muted":true},on:{"play":function($event){_vm.videoLoaded = true}}}),_c('video',{staticClass:"video-bg",style:({ opacity: _vm.currentBtn == 0 ? 0 : 1 }),attrs:{"src":"https://leaderboard.metacene.io/img/shade_video.mp4","autoplay":"","loop":"","muted":"","controls":false},domProps:{"muted":true}}),_vm._m(0),(_vm.videoLoaded)?_c('div',{staticClass:"btn-box"},_vm._l((3),function(index){return _c('div',{key:index,class:['btn', `btn${index}`, { active: _vm.actived(index) }],on:{"click":function($event){return _vm.changeActive(index)},"mouseenter":function($event){_vm.tempBtn = index},"mouseleave":function($event){_vm.tempBtn = 0}}},[_c('div',{staticClass:"btn-circle"}),_c('img',{staticClass:"btn-icon",attrs:{"src":_vm.actived(index)
						? require(`@/assets/rank-images/icon${index}_active.png`)
						: require(`@/assets/rank-images/icon${index}.png`)}}),_c('img',{staticClass:"btn-text",attrs:{"src":_vm.actived(index)
						? require(`@/assets/rank-images/text${index}_active.png`)
						: require(`@/assets/rank-images/text${index}.png`)}})])}),0):_vm._e(),_c('div',{staticClass:"icon_home_left_line",attrs:{"src":"@/assets/images/home_left_line.png"}}),_c('div',{staticClass:"icon_home_right_line",attrs:{"src":"@/assets/images/home_right_line.png"}}),_c('div',{staticClass:"bottom-line-cell"},[_c('span',{staticClass:"d-text"},[_vm._v("BUILDING A NEXT-GEN BLOCKCHAIN MMORPG HOMELAND FOR MASS PLAYERS")]),_c('img',{staticClass:"top-line",attrs:{"src":require("@/assets/images/decorator_line.png")}}),(_vm.currentBtn == 0)?_c('img',{staticClass:"bottom-text",attrs:{"src":require("@/assets/rank-images/tip.png")}}):_vm._e()]),_c('div',{staticClass:"words home",style:({ opacity: _vm.currentBtn == 0 ? 1 : 0, zIndex: _vm.currentBtn == 0 ? 1 : -1 })},[_c('img',{staticClass:"big-txt",attrs:{"src":require("@/assets/rank-images/title.png")}}),_c('img',{staticClass:"small-txt",attrs:{"src":require("@/assets/rank-images/subheading.png")}})]),_c('div',{staticClass:"words activity",style:({ opacity: _vm.currentBtn == 1 ? 1 : 0, zIndex: _vm.currentBtn == 1 ? 1 : -1 })},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"words charts",style:({ opacity: _vm.currentBtn == 2 ? 1 : 0, zIndex: _vm.currentBtn == 2 ? 1 : -1 })},[_c('charts')],1),_c('div',{staticClass:"words rules",style:({ opacity: _vm.currentBtn == 3 ? 1 : 0, zIndex: _vm.currentBtn == 3 ? 1 : -1 })},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fix-header"},[_c('div',{staticClass:"top-line-cell"},[_c('span',{staticClass:"d-text"},[_vm._v("BUILDING A NEXT-GEN BLOCKCHAIN MMORPG HOMELAND FOR MASS PLAYERS")]),_c('img',{staticClass:"top-line",attrs:{"src":require("@/assets/images/decorator_line.png"),"alt":""}})]),_c('div',{staticClass:"container-warpper"},[_c('a',{staticClass:"logo",attrs:{"href":"https://www.metacene.io/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo_white.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" ACTIVITY REWARDS"),_c('img',{attrs:{"src":require("@/assets/rank-images/arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('p',{staticClass:"big"},[_vm._v(" Season Rewards: "),_c('span',{staticClass:"highlight"},[_vm._v("600,000 $MAK + 4 Orange NFTs + 16 Purple 3 NFTs ")])]),_c('p',{staticStyle:{"font-size":"0.28rem"}},[_vm._v("(in total, and MAN holder benefits are not included)")]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Two Asia servers and America old server (Mercury's Wings): 120,000 $MAK+ 1 Orange gear+4 Purple 3gears (for each server)")])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("America new server (Venus' Heart): 240,000 $MAK + 1 Orange gear+4 Purple 3 gears")])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Rewards will be given to the top 100 players on each server")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('p',{staticClass:"big"},[_vm._v(" The top five players can receive "),_c('span',{staticClass:"highlight"},[_vm._v(" additional NFTs:")])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("1st place: Orange gear (Heavenly Boots)*1")])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("2nd and 3rd places: Purple 3 gear (observant Belt)*1")])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("4th and 5th places: Purple 3 gear(observant Boots)*1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('p',{staticClass:"big"},[_vm._v("Exclusive MAN Holders Privilege")]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Players with "),_c('span',{staticClass:"highlight"},[_vm._v("one MAN card")]),_vm._v(" can receive additional "),_c('span',{staticClass:"highlight"},[_vm._v("4%")]),_vm._v(" of the base rewards on the Leaderboard.With "),_c('span',{staticClass:"highlight"},[_vm._v("two cards")]),_vm._v(",you can earn "),_c('span',{staticClass:"highlight"},[_vm._v("8%")]),_vm._v(",and the more MAN cards you have,the higher your rewards - "),_c('span',{staticClass:"highlight"},[_vm._v("no upper limit!")])])]),_c('p',{staticClass:"small"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Each Man Card must be staked in the game backpack for at least "),_c('span',{staticClass:"highlight"},[_vm._v("20")]),_vm._v(" days to be counted in the activity.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module"},[_c('p',{staticClass:"small"},[_vm._v("Tips")]),_c('p',{staticClass:"smaller"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Final rewards of the leaderboard will be given to the top 100 players (each server) in the season's total points,distributed within 7 working days after the season ends,users can claim through website "),_c('a',{staticStyle:{"text-decoration":"underline","color":"rgba(94,155,233,1)"},attrs:{"href":"https://tmak.metacene.io/"}},[_vm._v("https://tmak.metacene.io/")])])]),_c('p',{staticClass:"smaller"},[_c('img',{attrs:{"src":require("@/assets/rank-images/rhombus.png")}}),_c('span',[_vm._v("Tie Resolution:In case of a tie,the $MAK rewards will be equally divided.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v("RULES"),_c('img',{attrs:{"src":require("@/assets/rank-images/arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"module small"},[_c('span',{staticClass:"big"},[_vm._v("Event Time:")]),_c('br'),_c('span',[_vm._v("Nov 5, 10:00 AM ~ Dec 2, 23:59(server time)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"module small"},[_c('span',{staticClass:"big"},[_vm._v("Duration:")]),_c('br'),_c('span',[_vm._v("This season lasts for "),_c('span',{staticClass:"highlight"},[_vm._v("28 days")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"module small"},[_c('span',{staticClass:"big"},[_vm._v("Ranking Composition:")]),_c('br'),_c('span',[_vm._v("MUD consumption("),_c('span',{staticClass:"highlight"},[_vm._v("40%")]),_vm._v(")")]),_vm._v(",Ferron consumption("),_c('span',{staticClass:"highlight"},[_vm._v("30%")]),_vm._v("), "),_c('br'),_c('span',[_vm._v("Excite Value Rank("),_c('span',{staticClass:"highlight"},[_vm._v("30%")]),_vm._v(")")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"module small"},[_c('span',{staticClass:"big"},[_vm._v("Bonus Points:")]),_c('br'),_c('span',[_vm._v("Shield Hammer class change，Flight Vehicle synthesis count, number of treasure box draws on-chain")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"module small"},[_c('span',{staticClass:"big"},[_vm._v("Data Update Time:")]),_c('br'),_c('span',[_vm._v("data collected daily at 23:59:59 (server time)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.metacene.io/news/f1021b13b7b94b7ea49fb573de17d194"}},[_c('img',{staticClass:"detail",attrs:{"src":require("@/assets/rank-images/rule_detail.png")}})])
}]

export { render, staticRenderFns }